<template>
  <div class="min-vh-100 d-flex flex-column">
    <header class="navbar navbar-expand navbar-dark sticky-top py-3 bg-transparent shadow bd-navbar">
      <nav class="container bd-gutter flex-wrap flex-lg-nowrap">
        <NuxtLink class="navbar-brand" to="/">楓之谷M</NuxtLink>

        <ul class="navbar-nav me-auto">
          <li v-for="route in routes" class="nav-item">
            <NuxtLink class="nav-link" :to="{ name: route.name }">{{ route.text }}</NuxtLink>
          </li>
        </ul>
      </nav>
    </header>

    <main class="mt-4 flex-grow-1 container">
      <NuxtPage/>
    </main>

    <footer class="py-4 mt-4 bg-light text-center">
      <span>{{ new Date().getFullYear() }} @ MSM Tools</span>
    </footer>
  </div>
</template>

<style lang="scss">
html {
  overscroll-behavior-y: none;
}

body {
  touch-action: manipulation;
}

.mobile {
  max-width: 390px;
}
</style>

<style lang="scss" scoped>
.bd-navbar {
  background-image: linear-gradient(to bottom, rgba(112.520718, 44.062154, 249.437846, 1), rgba(112.520718, 44.062154, 249.437846, 0.95));
}
</style>

<script lang="ts" setup>
const routes = [
  { name: 'guess-number', text: '猜數字' },
  { name: 'rock-paper-scissors', text: '剪刀石頭布' },
  { name: 'character-exp', text: '經驗值計算' },
]
</script>
