import { default as pages_47character_45exp_46vueMeta } from "/Users/kevin/Coding/msm/pages/character-exp.vue?macro=true";
import { default as pages_47guess_45number_46vueMeta } from "/Users/kevin/Coding/msm/pages/guess-number.vue?macro=true";
import { default as pages_47index_46vueMeta } from "/Users/kevin/Coding/msm/pages/index.vue?macro=true";
import { default as pages_47rock_45paper_45scissors_46vueMeta } from "/Users/kevin/Coding/msm/pages/rock-paper-scissors.vue?macro=true";
export default [
  {
    name: pages_47character_45exp_46vueMeta?.name ?? "character-exp",
    path: pages_47character_45exp_46vueMeta?.path ?? "/character-exp",
    children: [],
    meta: pages_47character_45exp_46vueMeta,
    alias: pages_47character_45exp_46vueMeta?.alias || [],
    redirect: pages_47character_45exp_46vueMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding/msm/pages/character-exp.vue").then(m => m.default || m)
  },
  {
    name: pages_47guess_45number_46vueMeta?.name ?? "guess-number",
    path: pages_47guess_45number_46vueMeta?.path ?? "/guess-number",
    children: [],
    meta: pages_47guess_45number_46vueMeta,
    alias: pages_47guess_45number_46vueMeta?.alias || [],
    redirect: pages_47guess_45number_46vueMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding/msm/pages/guess-number.vue").then(m => m.default || m)
  },
  {
    name: pages_47index_46vueMeta?.name ?? "index",
    path: pages_47index_46vueMeta?.path ?? "/",
    children: [],
    meta: pages_47index_46vueMeta,
    alias: pages_47index_46vueMeta?.alias || [],
    redirect: pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding/msm/pages/index.vue").then(m => m.default || m)
  },
  {
    name: pages_47rock_45paper_45scissors_46vueMeta?.name ?? "rock-paper-scissors",
    path: pages_47rock_45paper_45scissors_46vueMeta?.path ?? "/rock-paper-scissors",
    children: [],
    meta: pages_47rock_45paper_45scissors_46vueMeta,
    alias: pages_47rock_45paper_45scissors_46vueMeta?.alias || [],
    redirect: pages_47rock_45paper_45scissors_46vueMeta?.redirect || undefined,
    component: () => import("/Users/kevin/Coding/msm/pages/rock-paper-scissors.vue").then(m => m.default || m)
  }
]